import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import * as PR from "../../prime-modules/index";
import AdminFooter from "../layout/admin-footer";
import AdminHeader from "../layout/admin-header";
import { globalConfig } from '../../GlobalConfig';
import { useSelector, useDispatch } from 'react-redux';
import { getCountriesList, getCatalogsList, updatePrices, importPriceCsv, getPriceFilters } from "../../services/api.jsx";
import "../price-adjustment/PriceAdjustment.scss";
import * as utils from '../../utils';
import { getCurrentTimestamp } from "../../utils/reuse.js";

const PriceAdjustment = () => {
    const appName = process.env?.REACT_APP_NAME;
    const timeStamp = getCurrentTimestamp()
    const dispatch = useDispatch();
    const adminData = useSelector(state => state.adminAuth.adminSessionData);
    const headers = useMemo(() => {
        return { sessionid: adminData.sessionId };
    }, [adminData.sessionId]);
    const toast = useRef();

    const [catalogs, setCatalogs] = useState([]);
    const [editCatalogs, setEditCatalogs] = useState({});
    const [errorMessage, setErrorMessage] = useState("");

    const [sortField, setSortField] = useState('');
    const [sortOrder, setSortOrder] = useState(0);

    const [uniqueRegions, setUniqueRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(null);

    const [allCountries, setAllCountries] = useState([]);
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState('GB');
    const [loading, setLoading] = useState(true);

    const [filtersVisible, setFiltersVisible] = useState(false);


    const [providerList, setProviderList] = useState([]);
    const [durationList, setDurationList] = useState([]);
    const [dataAmountList, setDataAmountList] = useState([]);

    const [selectedPlans, setSelectedPlans] = useState(false);
    const [selectedProviders, setSelectedProviders] = useState([]);
    const [selectedDurations, setSelectedDurations] = useState([]);
    const [selectedDataAmounts, setSelectedDataAmounts] = useState([]);
    const [isChanged, setIsChanged] = useState(false);

    const [filterSelectedRegion, setFilterSelectedRegion] = useState(null);

    const [filterCountries, setFilterCountries] = useState([]);
    const [filterCountry, setFilterCountry] = useState('GB');

    const [filters, setFilters] = useState({});

    const [filterOpen, setFilterOpen] = useState(false);
    const toggleFilter = () => setFilterOpen(!filterOpen);

    const setDataAmount = (rowData) => {
        return (rowData.dataAmountForDisplay) ? rowData.dataAmountForDisplay : ((rowData.dataAmount / 1000) + (globalConfig.dataSize));
    };

    const setDataDuration = (rowData) => {
        return (rowData.duration && (rowData.duration) + " Days");
    };

    const setDataSpeed = (rowData) => {
        return (rowData.speed && (rowData.speed.join('/')));
    };
    const removePriceErrors = useCallback(() => {
        setCatalogs((prevCatalogs) =>
            prevCatalogs.map((catalog) => {
                const { errorMsg, ...rest } = catalog;
                return rest;
            })
        );
    }, [])

    const getCatalogs = useCallback(async () => {
        setLoading(true);
        const defaultFilters = {
            sortingOrder: 'asc',
            sortingField: 'providerprice',
            country,
        };
        const queryParams = utils.serializeParams({ ...filters, ...defaultFilters });
        
        const handleResponse = (response) => {
            setLoading(false);
            if (response.result === "SUCCESS") {
                const catalogData = response.data || [];
                let catalogList = catalogData;
                const editCatalogsData = {};
                if (Array.isArray(catalogData) && catalogData.length > 0) {
                    catalogList = catalogData.map((c, index) => {
                        const data = { ...c };
                        data.catalogId = c.name;
                        return data;
                    })
                    catalogList.forEach(c => {
                        editCatalogsData[c.catalogId] = { 
                            name: c.name,
                            providerPrice: c.providerPrice,
                            ourPrice: c.ourPrice,
                            editOurPrice: c.ourPrice,
                            offered: c.offered,
                            editOffered: c.offered,
                            error: ''
                        };
                    });
            
                } else {
                    toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'No plans for the selected country' });
                }
                setCatalogs(catalogList);
                setEditCatalogs(editCatalogsData);

            } else {
                const error = response?.error;
                toast.current?.show({ severity: error.severity, summary: 'Error', detail: error?.errorMsg || error?.summary });
            }
        };

        getCatalogsList(queryParams, headers, dispatch, handleResponse);
    }, [country, dispatch, filters, headers]);

    useEffect(() => {
        const handleCountriesResponse = (response) => {
            if (response.result === "SUCCESS") {
                const countriesData = response.data || [];
                if (countriesData.length > 0) {
                    const countriesList = countriesData.map(({ name, region, iso }) => ({
                        name: name.split('(')[0].trim(),
                        region,
                        iso
                    }));
                    setAllCountries(countriesList);
                    setCountries(countriesList);
                    setFilterCountries(countriesList);
                    const uniqueRegionList = [...new Set(countriesList.map(item => item.region))].map(region => ({ label: region, value: region }));
                    const unemptyRegionList = uniqueRegionList.filter(region => region.label && region.value).sort((a, b) => a.label.localeCompare(b.label)); // Removed empty object from the region list
                    setUniqueRegions(unemptyRegionList);
                }
            }
        };
        getCountriesList(headers, dispatch, handleCountriesResponse);
    }, [dispatch, headers]);

    useEffect(() => {
        getCatalogs();
    }, [getCatalogs]);

    useEffect(() => {
        setIsChanged(
            filterSelectedRegion ||
            filterCountry ||
            selectedPlans !== false ||
            selectedProviders.length > 0 ||
            selectedDurations.length > 0 ||
            selectedDataAmounts.length > 0
        );
    }, [selectedPlans, selectedProviders, selectedDurations, selectedDataAmounts, filterSelectedRegion, filterCountry]);

 const handleRegionSelection = (region) => {
        setSelectedRegion(region);
        setFilterSelectedRegion(region);
        const countriesByRegion = region ? allCountries?.filter(c => c.region === region) : allCountries;
        setCountries(countriesByRegion);
        setFilterCountries(countriesByRegion);
        const countryDisplay = countriesByRegion?.find(c => c.iso === country) ? country : null;
        setCountry(region ? countryDisplay : 'GB');
        setFilters(prev => ({ ...prev, region: region }));
    };

    const handleCountrySelection = (e) => {
        const selectedCountry = e.value;
        setCountry(selectedCountry);
        setFilterCountry(selectedCountry);
        setFilters(prev => ({ ...prev, region: selectedRegion, country: selectedCountry }));
    };

    const regionFilterHandler = (region) => {
        setFilterSelectedRegion(region);
        const countriesByRegion = region ? allCountries?.filter(c => c.region === region) : allCountries;
        const countryDisplay = countriesByRegion?.find(c => c.iso === filterCountry) ? filterCountry : null;
        setFilterCountry(region? countryDisplay : 'GB');
        setFilterCountries(countriesByRegion);
    }

    const countryFilterHandler = (e) => {
        setFilterCountry(e.value);
    }

    const handleFilterSubmit = (e) => {
        e.preventDefault();
        setFiltersVisible(false);
        setCountries(filterCountries);
        const countryDisplay = filterCountries?.find(c => c.iso === filterCountry) ? filterCountry : null;
        setCountry(countryDisplay);
        setSelectedRegion(filterSelectedRegion);
        setFilters(prev => ({
            ...prev,
            region: filterSelectedRegion,
            country: filterCountry,
            offered: selectedPlans ? selectedPlans : undefined,
            providerName: selectedProviders.includes('All') ? undefined : selectedProviders,
            planDuration: selectedDurations,
            dataAmountForDisplay: selectedDataAmounts
        }));
    };

    const resetData = () => {
        const editCatalogsData = {}
        catalogs.forEach(c => {
            editCatalogsData[c.catalogId] = { 
                ourPrice: c.ourPrice,
                editOurPrice: c.ourPrice,
                offered: c.offered,
                editOffered: c.offered,
                error: ''
            };
        });
        setEditCatalogs(editCatalogsData);
        setErrorMessage("");
        removePriceErrors();
        setSelectedRegion(null);
        setCountries(allCountries);
        setCountry('GB');
        setFilterSelectedRegion(null);
        setFilters([]);
        handleFilterReset();
    };

    const handleFilterReset = () => {
        setFilterSelectedRegion(null);
        setFilterCountries(allCountries);
        setFilterCountry('GB');
        setSelectedPlans(false);
        setSelectedProviders([]);
        setSelectedDurations([]);
        setSelectedDataAmounts([]);
    };

    const updateCatalogPrices = useCallback(async (catalogList) => {
        updatePrices(catalogList, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                getCatalogs();
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Price updated successfully' })
            } else {
                const error = response.error;
                toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
            }
        })
    }, [dispatch, headers, getCatalogs])

    const onPriceEdit = (rowData, e) => {
        const price = e.value;
        setEditCatalogs(prevState => {
            const updatedCatalogs = { ...prevState }; // Create a new object
            const catalog = updatedCatalogs[rowData.catalogId] || {}; // Prevent undefined errors
            if (price && price !== 0) {
                catalog.editOurPrice = utils.setNumberPrecision(price);
            } else {
                catalog.editOurPrice = null;
            }
            updatedCatalogs[rowData.catalogId] = catalog; // Update the state copy
            return updatedCatalogs;
        });
    };

    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <div className="list-price">
                    <span className="currencyVal">{globalConfig.defaultCurrency}</span>
                    <PR.InputNumber
                        value={editCatalogs[rowData.catalogId]?.editOurPrice}
                        onKeyDown={utils.blockInvalidChar}
                        onChange={(e) => onPriceEdit(rowData, e)}
                        minFractionDigits={2}
                        disabled={!editCatalogs[rowData.catalogId]?.editOffered}
                    />
                </div>
                {rowData.errorMsg ? <div className="priceError"> {rowData.errorMsg} </div> : ''}
            </>
        )
    };

    const providerPriceBodyTemplate = (rowData) => {
        return (
            <span>{utils.providerPrice(rowData.providerPrice)}</span>
        )
    };
    const getUpdateHandler = (editCatalogs) => {
       return Object.values(editCatalogs).filter(e => {
            if(e.ourPrice !== +e.editOurPrice){
                return true
            } else if(e.offered !== e.editOffered){
                return true;
            }
            return false;
        });
    }

    const updatePrice = () => {
        setErrorMessage('')
        const updatedCatalogData = getUpdateHandler(editCatalogs);
        if (updatedCatalogData.length > 0) {
            const priceUpdatedCatalogs = updatedCatalogData.filter(e => e.editOffered);
            const belowProviderPrice = priceUpdatedCatalogs.filter(u => u.providerPrice > +u.editOurPrice);
            const belowzero = priceUpdatedCatalogs.filter(u => u.editOurPrice === null || +u.editOurPrice < 0);
            if (belowProviderPrice.length > 0){
                setErrorMessage('List price should be greater than Buy price');
                return true;
            } else if(belowzero.length > 0){
                setErrorMessage('List price must be greater than zero');
                return true
            }
            const catalogList = updatedCatalogData.map((uc) => ({
              name: uc.name,
              price: !uc.editOffered ? uc.ourPrice : +uc.editOurPrice,
              providerPrice: uc.providerPrice,
              offered: uc.editOffered,
            }));
           updateCatalogPrices(catalogList);
        }
    }

    const offeredCheckboxTemplate = (rowData) => {
        return (
            <div className="card flex justify-content-center">
                <PR.Checkbox
                    onChange={e => {
                        setEditCatalogs(prevState => ({
                            ...prevState,
                            [rowData.catalogId]: {
                                ...prevState[rowData.catalogId],
                                editOffered: e.checked,
                            }
                        }));
                    }}
                    checked={editCatalogs[rowData.catalogId]?.editOffered}
                    className="offered-column"
                ></PR.Checkbox>
            </div>
        );
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];

        if (file.type === 'text/csv') {
            const formData = new FormData();
            formData.append('file', file);

            const selectedCsvFile = (response) => {
                setLoading(false);
                if (response.result === "SUCCESS") {
                    if (response.data === 'success') {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Prices updated successfully' });
                        getCatalogs();
                    } else {
                        toast.current.show({ severity: 'warn', summary: 'Warning', detail: response.data.errorMsg });
                    }
                } else {
                    const error = response.error;
                    toast.current.show({ severity: error.severity, summary: 'Error', detail: (error.errorMsg) ? error.errorMsg : error.summary })
                }
            }
            setLoading(true);
            importPriceCsv(formData, headers, dispatch, selectedCsvFile)
        } else {
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Uploaded file should be in .csv format' })
        }
        e.target.value = '';
    }

    const exportCSV = (e) => {
        setLoading(true);
        e.preventDefault();
        const defaultFilters = {
            sortingOrder: 'asc',
            sortingField: 'providerprice',
        };
        const queryParams = utils.serializeParams({ ...defaultFilters });
        getCatalogsList(queryParams, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                setLoading(false);
                const data = response.data ? response.data : [];
                 // Keys to extract
                const requiredKeys = ['country', 'iso', 'providerName', 'description', 'name',  'providerPrice', 'ourPrice', 'offered'];
                const exportArray = utils.extractRequiredKeys(data, requiredKeys)
                if (exportArray && exportArray.length > 0) {
                    const capitalizeFirstLetter = (str) => {
                        if (str === 'providerPrice') {
                            return 'Buy Price';
                        } else if (str === 'ourPrice') {
                            return 'List Price';
                        } else if (str === 'providerName') {
                            return 'Provider';
                        } else if (str === 'description') {
                            return 'Plan';
                        } else {
                            return str.charAt(0).toUpperCase() + str.slice(1);
                        }
                    };
                    const csvContent = "data:text/csv;charset=utf-8," +
                        Object.keys(exportArray[0]).map(capitalizeFirstLetter).join(",") + "\n" +
                        exportArray.map(entry => {
                            return Object.values(entry).map(value => {
                                if (typeof value === 'string' && (value.includes(' ') || value.includes(','))) {
                                    return `"${value}"`;
                                }
                                return value;
                            }).join(",");
                        }).join("\n");
                    const encodedUri = encodeURI(csvContent);
                    const link = document.createElement("a");
                    link.setAttribute("href", encodedUri);
                    link.setAttribute("download", `bo.${appName}-bundle-prices-${timeStamp}.csv`);
                    document.body.appendChild(link);
                    link.click();
                }
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Exported successfully' });
            } else {
                setLoading(false);
                const error = response?.error;
                toast.current?.show({ severity: error.severity, summary: 'Error', detail: (error?.errorMsg) ? error.errorMsg : error?.summary });
            }
        });
    };

    const rowClassName = (rowData) => {
        let classNames = '';
        const catalog = editCatalogs[rowData.catalogId] || {}
        if (!catalog.editOffered) {
            classNames += ' row-disabled';
        }
        if ((catalog.ourPrice !== +catalog.editOurPrice) || (catalog.offered !== catalog.editOffered)) {
            classNames += ' row-edited';  // Add a class when the row is edited
        }
        return classNames;
    };
    
    useEffect(() => {
        getPriceFilters(headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                setDataAmountList(response.data?.dataAmountForDisplay?.length > 0 ? [...new Set(response.data?.dataAmountForDisplay)] : []);
                setDurationList(response.data?.duration?.length > 0 ? [...new Set(response.data?.duration)] : []);
                // Include "All" option for providers
                const providers = response.data?.providers?.length > 0 ? ['All', ...new Set(response.data?.providers)] : [];
                setProviderList(providers);
            } else {
                const error = response?.error;
                toast.current?.show({ severity: error.severity, summary: 'Error', detail: (error?.errorMsg) ? error?.errorMsg : error?.summary })
            }
        })
    }, [dispatch, headers]);

    const handleProviderChange = (e, provider) => {
        if (provider === 'All') {
            if (e.checked) {
                setSelectedProviders(providerList);
            } else {
                setSelectedProviders([]);
            }
        } else {
            const newSelectedProviders = e.checked
                ? [...selectedProviders, provider]
                : selectedProviders.filter((p) => p !== provider);

            if (newSelectedProviders.length === providerList.length - 1 && !newSelectedProviders.includes('All')) {
                setSelectedProviders([...newSelectedProviders, 'All']);
            } else {
                setSelectedProviders(newSelectedProviders.filter((p) => p !== 'All'));
            }
        }
    };

    const handleDurationChange = (e, duration) => {
        const newSelectedDurations = e.checked
            ? [...selectedDurations, duration]
            : selectedDurations.filter((d) => d !== duration);
        setSelectedDurations(newSelectedDurations);
    };

    const handleDataAmountChange = (e, data) => {
        const newSelectedDataAmounts = e.checked
            ? [...selectedDataAmounts, data]
            : selectedDataAmounts.filter((d) => d !== data);
        setSelectedDataAmounts(newSelectedDataAmounts);
    };

    return (
        <>
            <div className="main">
                <div className="layout-sidebar">
                    <AdminHeader />
                </div>
                <div className="layout-content-wrapper">
                    <section className="admin-users-section price-adjust-section">
                        <div className="grid grid-nogutter">
                            <div className="col-12">
                                <div className="heading-sec">
                                    <div className="flex align-items-center justify-content-between mobile-flex">
                                        <div className="flex align-items-center justify-content-between width-full">
                                            <h1>Price Adjustment</h1>
                                            <PR.Button text className="filter-toggle-button" onClick={toggleFilter}>
                                                <i className={`pi ${filterOpen ? "pi-filter-slash" : "pi-filter-fill"}`}></i>
                                            </PR.Button>
                                        </div>
                                        <div className="right-section flex align-items-center gap-3">
                                            <div className="file-upload-section">
                                                <label className="placeholder-text"><i className="pi pi-upload"></i> Upload data</label>
                                                <PR.InputText type="file" name="file" className="file-upload-input csv-file" disabled={loading} accept=".csv" onChange={e => handleFileUpload(e)} />
                                            </div>
                                            <PR.Button label="Export CSV" className="export-button" icon="pi pi-file-export" iconPos="left" onClick={exportCSV} disabled={loading}/>
                                        </div>
                                    </div>
                                    <div className={`filter-section flex align-items-center justify-content-between mt-5 ${filterOpen ? "mobile-filter-section" : ""}`}>
                                            <div className="flex gap-3 align-items-center mobile-lex">
                                                <PR.Dropdown value={selectedRegion} onChange={(e) => handleRegionSelection(e.value)} options={uniqueRegions} optionLabel="label" placeholder="All Regions" filter className="w-full md:w-14rem" disabled={loading} 
                                                showClear={selectedRegion ? true : false}/>
                                                <PR.Dropdown
                                                    filter
                                                    disabled={loading}
                                                    value={country}
                                                    options={countries}
                                                    onChange={handleCountrySelection}
                                                    resetFilterOnHide
                                                    optionLabel="name"
                                                    optionValue="iso"
                                                    placeholder="All Countries"
                                                />
                                            </div>
                                        <PR.Button label="Filter" className="exportBtn" icon="pi pi-filter" onClick={() => setFiltersVisible(true)} disabled={loading}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="users-data-table price-adjustment plan-selection card">
                            <PR.DataTable
                                loading={loading}
                                editMode="row"
                                value={catalogs}
                                rowClassName={rowClassName}
                                let-i="rowIndex"
                                sortField={sortField}
                                sortOrder={sortOrder}
                                className="price-adjustment-table"
                                emptyMessage={<span className="text-gray-500">No results found</span>}
                                onSort={(e) => {
                                    setSortField(e.sortField);
                                    setSortOrder(e.sortOrder);
                                }}
                                >
                                <PR.Column field="providerName" header="Provider" sortable></PR.Column>
                                <PR.Column field="description" header="Plan" style={{width:"15%"}} className="plan-column" sortable />
                                <PR.Column body={(rowData) => (
                                    <div>
                                        <div>{rowData.name ? rowData.name : "-"}</div>
                                        <div style={{ marginTop: "4px"}}>
                                        {rowData.groups ? rowData.groups : "-"}
                                        </div>
                                    </div>
                                    )}  
                                    header="Bundle Name" field="name" style={{ width: "20%" }} sortable>
                                </PR.Column>
                                <PR.Column field="dataAmount" header="Data" style={{width:"8%"}} body={setDataAmount} sortable />
                                <PR.Column field="duration" header="Duration" style={{width:"8%"}} body={setDataDuration} sortable />
                                <PR.Column field="speed" header="Speed" body={setDataSpeed} />
                                <PR.Column field="providerPrice" header="Buy Price" body={providerPriceBodyTemplate} sortable />
                                <PR.Column field="ourPrice" header="List Price" style={{width:"10%"}} body={priceBodyTemplate} sortable />
                                <PR.Column filed="offered" header="Offered" style={{width:"8%"}} className="last-column" body={offeredCheckboxTemplate} />
                            </PR.DataTable>
                        </div>
                        {errorMessage  && <div className="priceError flex justify-content-end"> {errorMessage} </div> }
                        <div className="buttons-sections flex align-items-center justify-content-end gap-3">
                            <PR.Button label="Reset" className="confirm-button reset-btn" onClick={resetData} disabled={false} />
                            <PR.Button label="Confirm selection" className="confirm-button" onClick={() => updatePrice()} 
                            disabled={getUpdateHandler(editCatalogs).length === 0} />
                        </div>
                    </section>

                    <PR.Dialog header="Filter" visible={filtersVisible} onHide={() => { if (!filtersVisible) return; setFiltersVisible(false); }} draggable={false} blockScroll={true} className="filter-dialog">
                        <form autoComplete="off" className="mt-0" onSubmit={handleFilterSubmit}>
                            <div className="flex gap-3 align-items-center flex-wrap">
                                <PR.Dropdown value={filterSelectedRegion} onChange={(e) => regionFilterHandler(e.value)} options={uniqueRegions} optionLabel="label" placeholder="All Regions" filter className="w-full md:w-14rem" disabled={loading} resetFilterOnHide showClear="true"/>
                                <PR.Dropdown
                                    filter
                                    disabled={loading}
                                    value={filterCountry}
                                    options={filterCountries}
                                    onChange={countryFilterHandler}
                                    resetFilterOnHide
                                    optionLabel="name"
                                    optionValue="iso"
                                    placeholder="All Countries"
                                />
                                <div className="flex mt-2">
                                    <PR.Checkbox name="selectedPlans" onChange={(e) => setSelectedPlans(e.checked)} checked={selectedPlans}></PR.Checkbox>
                                    <label htmlFor="selectedPlans" className="ml-2">Show only selected plans</label>
                                </div>
                            </div>
                            {providerList.length > 0 && <div className="card mt-4">
                                <p>By list of providers</p>
                                <div className="flex gap-5 flex-wrap">
                                    {
                                        providerList.length > 0 && providerList.map((provider, index) =>
                                            <div className="flex" key={index}>
                                                <PR.Checkbox inputId={`provider${index}`} checked={selectedProviders.includes(provider)} onChange={(e) => handleProviderChange(e, provider)}></PR.Checkbox>
                                                <label htmlFor="provider" className="ml-2">{provider}</label>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>}
                            {durationList.length > 0 && <div className="card mt-4">
                                <p>By plan duration</p>
                                <div className="flex gap-5 flex-wrap">
                                    {
                                        durationList.length > 0 && durationList.map((duration, index) =>
                                            <div className="flex" key={index}>
                                                <PR.Checkbox inputId={`duration${index}`} checked={selectedDurations.includes(duration)} onChange={(e) => handleDurationChange(e, duration)}></PR.Checkbox>
                                                <label htmlFor="duration" className="ml-2">{duration} {duration > 1 ? 'days' : 'day'}</label>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>}
                            {dataAmountList.length > 0 && <div className="card mt-4">
                                <p>By amount of data</p>
                                <div className="flex gap-5 amount-data-options flex-wrap">
                                    {
                                        dataAmountList.length > 0 && dataAmountList.map((data, index) =>
                                            <div className="flex" key={index}>
                                                <PR.Checkbox inputId={`data${index}`} checked={selectedDataAmounts.includes(data)} onChange={(e) => handleDataAmountChange(e, data)}></PR.Checkbox>
                                                <label htmlFor="amount" className="ml-2">{data}</label>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>}
                            <div className="buttons-sections flex align-items-center justify-content-end gap-3">
                                <PR.Button label="Reset" type="button" className="confirm-button reset-btn min-width" onClick={handleFilterReset} disabled={!isChanged}/>
                                <PR.Button label="Apply" type="submit" className="confirm-button min-width"/>
                            </div>
                        </form>
                    </PR.Dialog>
                    <PR.Toast ref={toast} position='top-right' />
                    <AdminFooter />
                </div>
            </div>
        </>
    );
};

export default PriceAdjustment;